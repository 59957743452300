import useHttp from "~/composables/useHttp";

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    dashboard: {
      candidates: {
        need_of_workers: 0,
        new: 0,
        total: 0,
      },
      request: {
        new: 0,
        total: 0,
      },
      vacancies: {
        new: 0,
        total: 0,
      },
    },
  }),

  getters: {
    getDashboard: (state) => {
      return state.dashboard;
    },
    getAllNeedWorkers: (state) => {
      return state.dashboard.candidates.need_of_workers || 0
    },
  },

  actions: {
    async fetch() {
      try {
        // if (this.HRs.length) {
        //   return;
        // }

        const { data } = await useHttp("/dashboard");

        this.$patch((state) => (state.dashboard = { ...data }));
      } catch (e) {
        console.error(e);
      }
    },
  },
});
